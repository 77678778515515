<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防设备'"
      :btnFlag="btnFlag"
    >

    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritExamine",
      searchVal: "",
      jobList: [],
      searchQuery: {  },
      btnFlag:{addShow: false, editShow: false, deleteShow: false},
      props: [
        {
          label: "账号",
          prop: "account",
          align: "center",
          width:"250px",
        },

        {
          label: "巡检人",
          prop: "name",
          align: "center",
          width:"200px",
        },
        {
          label: "应得分",
          prop: "reservedScore",
          align: "center",
        },

        {
          label: "实得分",
          prop: "obtainedScore",
          align: "center",
        },
        {
          label: "更新时间",
          prop: "createTime",
          align: "center",
        },
        
      ],

      formProps: [
        {
          label: "设备名称",
          prop: "deviceName",
          type: "input",
        },

        {
          label: "设备编号",
          prop: "deviceNum",
          type: "input",
        },
        {
          label: "位置",
          prop: "locationName",
          type: "input",
        },
        {
          label: "设备状态",
          prop: "deviceStatus",
          type: "select",
          selectData: [
            { label: "正常", value: 0 },
            { label: "异常", value: 1 },
          ],
        },
        {
          label: "备注",
          prop: "issue",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
